<template>
  <div>
    <div class="img" />
    <div class="navbar">
      <div class="tabbar">
        <div v-for="item in contentList" :key="item.uuid" :class="{'check': article.uuid === item.uuid, 'tab': true}" @click="getTab(item.uuid)">
            {{item.title}}
        </div>
        <div :class="{'check': article.uuid === 1, 'tab': true}" @click="internet">
            <span>互联网保险产品</span>
        </div>
        <div :class="{'check': article.uuid === 2, 'tab': true}" @click="cooperate">
            <span>合作保险机构</span>
        </div>
      </div>
      <div class="msgs">
        <div v-if="article.uuid !== 1 && article.uuid !== 2" class="basic">
          <div class="title"><div class="blue" />{{article.title}}</div>
          <div class="main" v-html="article.content" />
        </div>
        <div v-if="article.uuid === 1" class="basic">
          <div class="title"><div class="blue" />互联网保险产品</div>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="name" label="承保公司" />
            <el-table-column prop="productName" label="销售产品名称" width="120" />
            <el-table-column prop="recordClause" label="备案产品/条款名称"  />
            <el-table-column prop="filingNo" label="备案编号" width="150" />
            <el-table-column prop="policyVerification" label="电子保单验真或查询 下载途径">
              <template slot-scope="scope">
                <span style="color: #007AF7;cursor: pointer;" @click="goPage(scope.row.policyVerification)">{{scope.row.policyVerification}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="policyWording" label="查看">
              <template slot-scope="scope">
                <span style="color: #007AF7;cursor: pointer;" @click="download(scope.row.policyWordingUrl)">{{scope.row.policyWording}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="article.uuid === 2" class="basic">
          <div class="title"><div class="blue" />合作保险机构</div>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="name" label="公司名称" />
            <el-table-column prop="cooperateDate" label="合作期限" width="120" />
            <el-table-column prop="cooperateRange" label="合作范围"  />
            <el-table-column prop="serviceOrganization" label="省级分支机构和落地 服务机构的省" width="150" />
            <el-table-column prop="phone" label="服务电话" />
            <el-table-column prop="accessMode" label="在线服务 访问方式" />
            <el-table-column prop="companyWebsite" label="偿付能力、风险综合评 级、消费者权益保护" width="180">
              <template slot-scope="scope">
                <span style="color: #007AF7;cursor: pointer;" @click="goPage(scope.row.companyWebsite)">{{scope.row.companyWebsite}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="foot">
      <span>Copyright © 上海仁信保险经纪有限公司 版权所有  <a href="https://beian.miit.gov.cn" style="color: #B1A9A9;">沪ICP备12012639号-9 </a> </span>
    </div>
  </div>
</template>

<script>
import {article, cooperativeInstitutions, insuranceProducts} from '@/api/home'
export default {
  data() {
    return {
      article: {},
      contentList: [],
      tableData: []
    }
  },
  created() {
    this.refresh()
  },
  methods: {
    refresh() {
      article({page: 1, size: 100, sort: 'id,desc', sectionCode: '3'}).then(res => {
        this.contentList = res.content
        this.article = res.content[0]
      })
    },
    getTab(uuid) {
      console.log(uuid);
      this.contentList.map(item => {
        if (item.uuid === uuid) {
          this.article = item
        }
      })
    },
    internet() {
      this.article = {uuid: 1}
      insuranceProducts().then(res => {
        this.tableData = res.content
      })
    },
    cooperate() {
      this.article = {uuid: 2}
      cooperativeInstitutions().then(res => {
        this.tableData = res.content
      })
    },
    goPage (url) {
      window.open(url)
    },
    download(url) {
      window.open(`${process.env.VUE_APP_BASE_API}/official/api/file/download?source=${url}`, "_self")
    }
  }
}
</script>

<style lang="less" scoped>
@import url("../../assets/css/ql.less");
.img {
  height: 180px;
  background: url('../../assets/img/img信息披露.png') no-repeat center;
}
.navbar {
  background: #F4F5F6;
  display: flex;
  justify-content: center;
  padding: 40px 0 80px;
  .tabbar {
    margin-right: 20px;
    .tab {
      width: 230px;
      height: 62px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      border-bottom: solid 1px #F4F5F6;
      padding-left: 50px;
      cursor: pointer;
    }
    .check{
      font-weight: bold;
      color: #2E4E9A;
      border-right: solid 3px #2E4E9A;
    }
  }
  .msgs {
    width: 930px;
    min-height: 706px;
    background: #FFFFFF;
    .basic {
      padding: 30px 50px;
      .title {
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-bottom: 40px;
        .blue {
          width: 4px;
          height: 16px;
          background: #2E4E9A;
          border-radius: 2px;
          margin-right: 10px;
        }
      }
      .main {
        user-select: text;
        /deep/ p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: Regular;
          color: #666666;
          line-height: 24px;
        }
        /deep/ a {
          color: #06c;
          text-decoration: underline;
        }
      }
    }
  }
}
.foot {
  height: 64px;
  background: #393D41;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #B1A9A9;
}
</style>
